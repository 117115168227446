import React, { useState, useEffect } from 'react';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import russianMessages from 'ra-language-russian';
import {
  Admin, 
  Resource, 
  fetchUtils, 
  useGetIdentity, 
  CustomRoutes, 
  defaultTheme, 
  nanoLightTheme, 
  nanoDarkTheme,
  radiantLightTheme, 
  radiantDarkTheme,
  houseLightTheme, 
  houseDarkTheme,
  Layout,
} from 'react-admin';
import MyAppBar from './design/MyAppBar';
import MyMenu from './design/MyMenu';
import simpleRestProvider from 'ra-data-simple-rest';
import { authProvider } from './authProvider';
import axios from 'axios';
import { omit } from 'lodash';
import { Route, useLocation } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';

import CompanyList from './companies/CompanyList';
import CompaniesCreate from './companies/CompaniesCreate';
import CompaniesEdit from './companies/CompaniesEdit';
import CompaniesShow from './companies/CompaniesShow';

import ContactList from './contacts/ContactList';
import ContactCreate from './contacts/ContactCreate';
import ContactEdit from './contacts/ContactEdit';

import ContragentList from './contragents/ContragentList';
import ContragentCreate from './contragents/ContragentCreate';
import ContragentEdit from './contragents/ContragentEdit';

import AccountList from './accounts/AccountList';
import AccountCreate from './accounts/AccountCreate';
import AccountEdit from './accounts/AccountEdit';
import AccountShow from './accounts/AccountShow';
import WorkShiftList from './accounts/WorkShiftList';
import LunchButtons from './accounts/LunchButtons';

import RequestList from './requests/RequestList';
import RequestCreate from './requests/RequestCreate';
import RequestEdit from './requests/RequestEdit';
import RequestGroupEdit from './requests/RequestGroupEdit';
import RequestListForContacts from './requests/RequestListForContacts';

import RequestIncomeSourceList from './requestIncomeSources/RequestIncomeSourceList';
import RequestIncomeSourceCreate from './requestIncomeSources/RequestIncomeSourceCreate';
import RequestIncomeSourceEdit from './requestIncomeSources/RequestIncomeSourceEdit';

import TaskTypeList from './taskTypes/TaskTypeList';
import TaskTypeCreate from './taskTypes/TaskTypeCreate';
import TaskTypeEdit from './taskTypes/TaskTypeEdit';

import FunnelList from './funnels/FunnelList';
import FunnelCreate from './funnels/FunnelCreate';
import FunnelEdit from './funnels/FunnelEdit';

import VillageList from './villages/VillageList';
import VillageCreate from './villages/VillageCreate';
import VillageEdit from './villages/VillageEdit';
import VillageShow from './villages/VillageShow';

import TaskList from './tasks/TaskList';
import TaskCreate from './tasks/TaskCreate';
import TaskShow from './tasks/TaskShow';
import TaskEdit from './tasks/TaskEdit';

import DealList from './deals/DealList';
import DealCreate from './deals/DealCreate';
import DealEdit from './deals/DealEdit';

import LogsList from './logs/LogsList';
import LogShow from './logs/LogShow';

import NotificationsList from './notifications/NotificationsList';
import Notifications from './notifications/Notifications';

import SanctionsList from './sanctions/SanctionsList';
import SanctionsEdit from './sanctions/SanctionsEdit';

import Dashboard from './dashboard/Dashboard';

import { PopupProvider  } from './design/PopupContext'; 
import MyLoginPage from './design/MyLoginPage';
import SettingsPage from './design/SettingsPage';
import ThemeSettings from './design/ThemeSettings';
import { themes, lightTheme, darkTheme } from './design/themes';

import DocumentEditor from './documents/DocumentEditor';

import AnalyticsPage from './analytics/AnalyticsPage';

import AccountingPage from './accounting/AccountingPage';
import SalariesPage from './accounting/SalariesPage';
import DebitoryPage from './accounting/DebitoryPage';
import DebitoryPayments from './accounting/DebitoryPayments';
import DebitoryActions from './accounting/DebitoryActions';
import AccountingEditForm from './accounting/AccountingEditForm';

import LeadScreenWebsiteList from './leadScreenWebsites/LeadScreenWebsiteList';
import LeadScreenWebsiteCreate from './leadScreenWebsites/LeadScreenWebsiteCreate';
import LeadScreenWebsiteEdit from './leadScreenWebsites/LeadScreenWebsiteEdit';

import MessagesTemplateList from './messagesTemplates/MessagesTemplateList';
import MessagesTemplateCreate from './messagesTemplates/MessagesTemplateCreate';
import MessagesTemplateEdit from './messagesTemplates/MessagesTemplateEdit';

import MeetList from './meetings/MeetList';
import MeetEdit from './meetings/MeetEdit';
import MeetCreate from './meetings/MeetCreate';
import MeetCreatePopup from './meetings/MeetCreatePopup';

import WebsiteList from './websites/WebsiteList';
import WebsiteCreate from './websites/WebsiteCreate';
import WebsiteEdit from './websites/WebsiteEdit';

import WebBlockList from './webBlocks/WebBlockList';
import WebBlockCreate from './webBlocks/WebBlockCreate';
import WebBlockEdit from './webBlocks/WebBlockEdit';

import RealtyListingsList from './realtyListings/RealtyListingsList';
import RealtyListingsMap from './realtyListings/RealtyListingsMap';
import RealtyListingShow from './realtyListings/RealtyListingShow';
import RealtyListingUploadService from './realtyListings/RealtyListingUploadService';

import RealtyPublishList from './realtyPublish/RealtyPublishList';
import RealtyPublishCreate from './realtyPublish/RealtyPublishCreate';
import RealtyPublishEdit from './realtyPublish/RealtyPublishEdit';

const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru');

const fetchJson = (url, options = {}) => {
    options.user = {
        authenticated: true,
        token: localStorage.getItem('token')
    };
    return fetchUtils.fetchJson(url, options);
};

async function convertFileToBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = function () {
      const arrayBuffer = reader.result;
      resolve(arrayBuffer);
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
}

const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;

        reader.readAsDataURL(file.rawFile);
    });

export const endpoint = "https://mfcnrealty.sotka-api.ru";
export const wsEndpoint = "wss://mfcnrealty.sotka-api.ru/ws";

const dataProvider = simpleRestProvider(endpoint, fetchJson);

const MyDataProvider = {
  ...dataProvider,
 create: async (resource, params) => {
  if(resource === "sales" && params.data.picture){
    const filteredObject = omit(params.data, 'picture');

    return convertFileToBase64(params.data.picture).then(picture64 => 
        dataProvider.create(resource, { 
            ...params,
            data : {
                ...filteredObject,
                avatar : picture64
            }
        })
    );
  }
  if (resource !== 'delete' && (params.data.file || params.data.floorPlanFile || params.data.photos || params.data.files)) {
     const formData = new FormData();
   
     // Dynamically append all fields from params.data to formData
     Object.entries(params.data).forEach(([key, value]) => {
       if (Array.isArray(value)) {
        if(key !== 'photos' && key !== 'files'){
         // Check if the array contains objects (e.g., estates)
         if (typeof value[0] === 'object' && value[0] !== null) {
           // Handle array of objects
           value.forEach((item, index) => {
             Object.entries(item).forEach(([subKey, subValue]) => {
               formData.append(`${key}[${index}][${subKey}]`, subValue);
             });
           });
         } else {
           // Handle simple arrays (e.g., array of strings or numbers)
           value.forEach((item, index) => {
             formData.append(`${key}[${index}]`, item);
           });
         }
        }else{
          value.forEach(file => {
            formData.append(key, file.rawFile);
          });
        }
       } else if(value !== null && value !== undefined){
        if (typeof value === 'boolean') {
          formData.append(key, String(value)); 
        }else{
          formData.append(key, key === 'file' || key == 'floorPlanFile' ? value.rawFile : value);
        }
       }
     });
   
     // Ensure tableName is also added
     formData.append("tableName", resource);
   
     try {
       const response = await fetch(`${endpoint}/upload`, {
         method: "POST",
         body: formData,
         headers: {
           'Authorization': localStorage.getItem('token'),
         },
       });
   
       const json = await response.json();
       return { data: json };
     } catch (error) {
       console.error("Error uploading file:", error);
       throw error;
     }
   }

   // Fallback to the default create method for other resources
   return dataProvider.create(resource, params);
 },
 update: async (resource, params) => {
  if(resource === "sales" && params.data.picture){
    const filteredObject = omit(params.data, 'picture');

    return convertFileToBase64(params.data.picture).then(picture64 => 
        dataProvider.update(resource, { 
            ...params,
            data : {
                ...filteredObject,
                avatar : picture64
            }
        })
    );
  }
  if (params.data.file || params.data.floorPlanFile || params.data.photos || params.data.files){
       const formData = new FormData();
   
       // Dynamically append all fields from params.data to formData
       Object.entries(params.data).forEach(([key, value]) => {
         if (Array.isArray(value)) {
          if(key !== 'photos' && key !== 'files'){
           // Check if the array contains objects (e.g., estates)
           if (typeof value[0] === 'object' && value[0] !== null) {
             // Handle array of objects
             value.forEach((item, index) => {
               Object.entries(item).forEach(([subKey, subValue]) => {
                 formData.append(`${key}[${index}][${subKey}]`, subValue);
               });
             });
           } else {
             // Handle simple arrays (e.g., array of strings or numbers)
             value.forEach((item, index) => {
               formData.append(`${key}[${index}]`, key == 'files' || key == 'photos' ? item.rawFile : item);
             });
           }
          }else{
            value.forEach(file => {
              formData.append(key, file.rawFile);
            });
          }
         }else if(value !== null && value !== undefined){
            if (typeof value === 'boolean') {
              formData.append(key, String(value)); 
            }else{
              formData.append(key, key === 'file' || key == 'floorPlanFile' ? value.rawFile : value);
            }
         }
       });
     
       // Ensure tableName is also added
       //formData.append("tableName", resource);
       //formData.append("entity_id", params.data.id);
 
       try {
         const response = await fetch(`${endpoint}/upload`, {
           method: "PUT",
           body: formData,
           headers: {
             'Authorization': `${localStorage.getItem('token')}`,
           },
         });
 
         const json = await response.json();
         return { data: json };
       } catch (error) {
         console.error("Error uploading file:", error);
         throw error;
       }
     }

     return dataProvider.update(resource, params);
   }
};

const SotkaTheme = {
    /*...defaultTheme,
    palette: {
        primary: {
            main: '#7100e2',
            light: '#7100e2',
            dark: '#7100e2',
            },
        secondary: '#dd8500',
        error: '#ff0000',
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    typography: {
        // Use the system font instead of the default Roboto font.
       // fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
    },*/
    ...defaultTheme,
    palette: {
        primary: {
          main: '#003801',
        },
        secondary: {
          //main: '#FF8100',
        },
  },
};


function App() {
  const [showFloatingWindow, setShowFloatingWindow] = useState(true);

  return (
    <>
    <Notifications /*goToPage={goToPage}*/ />
    <LunchButtons />
      <Admin 
      dataProvider={MyDataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      //dashboard={Dashboard}
      layout={(props) =>  
      <PopupProvider>
        <Layout {...props} appBar={MyAppBar} menu={MyMenu}>
          <MeetCreatePopup />
          {props.children}
        </Layout>
      </PopupProvider>
      }
      loginPage={MyLoginPage}
      theme={lightTheme}
      darkTheme={darkTheme}
      disableTelemetry
      >
       <Resource options={{ label: 'Заявки' }} name='requests' list={RequestList} create={RequestCreate} edit={RequestEdit} />
       <Resource options={{ label: 'Задачи' }} name='requestsHistory' list={TaskList} create={TaskCreate} show={TaskShow} edit={TaskEdit} />
       <Resource options={{ label: 'Сделки' }} name='deals' list={DealList} create={DealCreate} edit={DealEdit} />
       <Resource options={{ label: 'Клиенты' }} name='all_requests' list={RequestListForContacts} create={RequestCreate} edit={RequestEdit} />
       <Resource options={{ label: 'Данные контактов' }} name='contacts' list={ContactList} create={ContactCreate} edit={ContactEdit} />
       <Resource options={{ label: 'Контрагенты' }} name='contragents' list={ContragentList} create={ContragentCreate} edit={ContragentEdit} />
       <Resource options={{ label: 'Компании' }} name='companies' list={CompanyList} create={CompaniesCreate} edit={CompaniesEdit} show={CompaniesShow} />
       <Resource options={{ label: 'Редактор воронок' }} name='funnels' list={FunnelList} create={FunnelCreate} edit={FunnelEdit} />
       <Resource options={{ label: 'Каналы заявок' }} name='requestIncomeSources' list={RequestIncomeSourceList} create={RequestIncomeSourceCreate} edit={RequestIncomeSourceEdit} />
       <Resource options={{ label: 'Типы задач' }} name='taskTypes' list={TaskTypeList} create={TaskTypeCreate} edit={TaskTypeEdit} />
       <Resource options={{ label: 'Поселки (участки)' }} name='villages' list={VillageList} create={VillageCreate} edit={VillageEdit} show={VillageShow} />
       <Resource options={{ label: 'История изменений' }} name='logs' list={LogsList} show={LogShow} />
       <Resource options={{ label: 'Уведомления' }} name='notifications' list={NotificationsList} />
       <Resource options={{ label: 'Пользователи' }} name='sales' list={AccountList} create={AccountCreate} edit={AccountEdit} show={AccountShow} />
       <Resource options={{ label: 'График работы' }} name='workshift' list={WorkShiftList} create={AccountCreate} edit={AccountEdit} show={AccountShow} />
       <Resource options={{ label: 'Штрафы и санкции' }} name='sanctions' list={SanctionsList} edit={SanctionsEdit} />
       <Resource options={{ label: 'Сервисы карточки лида' }} name='leadScreenWebsites' list={LeadScreenWebsiteList} create={LeadScreenWebsiteCreate} edit={LeadScreenWebsiteEdit} />
       <Resource options={{ label: 'Встречи' }} name='meetings' list={MeetList} create={MeetCreate} edit={MeetEdit} />
       <Resource options={{ label: 'Объявления' }} name='realtyListings' list={RealtyListingsList} />
       <Resource options={{ label: 'Объявления на карте' }} name='realtyListingsMap' list={RealtyListingsMap} />
       <Resource options={{ label: 'Размещение объявлений' }} name='realtyPublish' list={RealtyPublishList} create={RealtyPublishCreate} edit={RealtyPublishEdit} />
       <Resource options={{ label: 'Поступления' }} name='accounting' edit={AccountingEditForm} />
       <Resource options={{ label: 'Шаблоны сообщений' }} name='messagesTemplates' list={MessagesTemplateList} create={MessagesTemplateCreate} edit={MessagesTemplateEdit} />
       <Resource options={{ label: 'Создание сайтов' }} name='w' list={WebsiteList} create={WebsiteCreate} edit={WebsiteEdit} />
       <Resource options={{ label: 'Конструктор блоков' }} name='webBlocks' list={WebBlockList} create={WebBlockCreate} edit={WebBlockEdit} />
       <CustomRoutes>
            <Route options={{ label: 'Настройки' }} path="/settings" element={<SettingsPage />} />
       </CustomRoutes>
       <CustomRoutes>
            <Route options={{ label: 'Аналитика' }} path="/analytics" element={<AnalyticsPage />} />
       </CustomRoutes>
       <CustomRoutes>
            <Route options={{ label: 'Премиальные' }} path="/accounting" element={<AccountingPage />} />
       </CustomRoutes>
       <CustomRoutes>
            <Route options={{ label: 'Зарплатная ведомость' }} path="/salaries" element={<SalariesPage />} />
       </CustomRoutes>
       <CustomRoutes>
            <Route options={{ label: 'Дебиторская задолженность' }} path="/debitory" element={<DebitoryPage />} />
       </CustomRoutes>
       <CustomRoutes>
            <Route options={{ label: 'Список платежей' }} path="/debitoryPayments" element={<DebitoryPayments />} />
       </CustomRoutes>
       <CustomRoutes>
            <Route options={{ label: 'Действия бухгалтерии' }} path="/debitoryActions" element={<DebitoryActions />} />
       </CustomRoutes>
       <CustomRoutes>
            {/*<Route options={{ label: 'Новое объявление' }} path="/realtyUpload" element={<RealtyListingUploadService />} />*/}
            <Route path="/realtyListings/:realty_id/show" element={<RealtyListingShow />} />
       </CustomRoutes>
       <CustomRoutes>
            <Route options={{ label: 'Групповое редактирование заявок' }} path="/request_group_edit" element={<RequestGroupEdit />} />
       </CustomRoutes>
       <CustomRoutes noLayout>
            <Route options={{ label: 'Редактирование документа' }} path="/document_edit" element={<DocumentEditor />} />
       </CustomRoutes>
       <CustomRoutes>
            <Route options={{ label: 'Настройки темы' }} path="/theme_settings" element={<ThemeSettings />} />
       </CustomRoutes>
      </Admin>
      </>
  );
}

export default App;