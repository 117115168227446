import * as React from 'react';
import { useState, useEffect } from 'react';
import {
    List as RaList,
    Loading,
    useListContext,
    NumberInput,
    DateTimeInput,
    TextInput,
    SelectInput,
    AutocompleteArrayInput,
    TopToolbar,
    useNotify,
    required
} from 'react-admin';
import { Box, Button, Card, CardContent, InputAdornment, Typography, Grid, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import SearchIcon from '@mui/icons-material/Search';
import { YMaps, Map, Polygon, Placemark } from 'react-yandex-maps';
import { categories, nedvigimostTypes, sources, cities } from './selectables';
import Gallery from './Gallery';

const getRandomDecimal = () => {
    const min = 0.000000000001;
    const max = 0.000000000999;
    return parseFloat((Math.random() * (max - min) + min).toFixed(12)); // Генерирует случайное число с 12 знаками после запятой
};

// FilterForm Component
const FilterForm = ({ polygonCoords }) => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { filterValues, setFilters } = useListContext();
    const notify = useNotify();
    const form = useForm({
        defaultValues: filterValues,
    });

    const onSubmit = (values) => {
        values.city = values.city?.join('|');
        values.category_id = values.category_id?.join(',');
        values.nedvigimost_type = values.nedvigimost_type?.join(',');
        values.source = values.source?.join(',');

        var filtersObj = {
            [`area[${0}][lat]`]: polygonCoords[0],
            [`area[${0}][lng]`]: polygonCoords[1],
            [`area[${1}][lat]`]: polygonCoords[2],
            [`area[${1}][lng]`]: polygonCoords[3],
            [`area[${2}][lat]`]: polygonCoords[4],
            [`area[${2}][lng]`]: polygonCoords[5],
            [`area[${3}][lat]`]: polygonCoords[6],
            [`area[${3}][lng]`]: parseFloat(polygonCoords[7]) + getRandomDecimal(),
            is_actual: '11,1',
            limit: values.limit ? values.limit : 50,
            ...values
        };

        console.log(JSON.stringify(filtersObj));

        setFilters(filtersObj);
    };

    return (
        <FormProvider {...form}>
            <Typography variant="body2" color='textSecondary' textAlign={isMobile ? "start" : "center"} sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Наведите на нужную область на карте, настройте параметры поиска, затем нажмите <strong>"Показать объекты на карте"</strong></Typography>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <Box display="flex" flex="1"  gap="0.5rem" alignItems={isMobile ? "start" : "center"} mt={.5} flexDirection={isMobile ? "column" : "row"}>
                    <Box mr={1}>
                        <AutocompleteArrayInput
                            source="category_id"
                            label="Категория"
                            choices={categories}
                            helperText={false}
                        />
                    </Box>
                    <Box mr={1}>
                        <AutocompleteArrayInput
                            source="nedvigimost_type"
                            label="Тип объявления"
                            choices={nedvigimostTypes}
                            helperText={false}
                        />
                    </Box>
                    <Box mr={1}>
                        <AutocompleteArrayInput
                            source="source"
                            label="Источник"
                            choices={sources}
                            helperText={false}
                        />
                    </Box>
                    <Box mr={1}>
                        <SelectInput
                            source="limit"
                            label="Лимит отображения"
                            choices={[
                                { id: 50, name: '50 шт.' },
                                { id: 100, name: '100 шт.' },
                                { id: 250, name: '250 шт.' },
                            ]}
                            defaultValue={50}
                            validate={required()} 
                            helperText={false}
                        />
                    </Box>
                </Box>
                <Box display="flex" flex="1" flexWrap="wrap" gap="0.5rem" mt={1} alignItems={isMobile ? "start" : "center"} flexDirection={isMobile ? "column" : "row"}>
                    <Box mr={1}>
                        <TextInput
                            resettable
                            helperText={false}
                            source="q"
                            label="Ключевые слова"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon color="disabled" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box mr={1}>
                        <NumberInput source="price1" label="Цена от, руб." helperText={false} />
                    </Box>
                    <Box mr={1}>
                        <NumberInput source="price2" label="Цена до, руб." helperText={false} />
                    </Box>
                    <Box mr={1}>
                        <DateTimeInput source="date1" label="Время от" helperText={false} />
                    </Box>
                    <Box mr={1}>
                        <DateTimeInput source="date2" label="Время до" helperText={false} />
                    </Box>
                    <Box mr={1}>
                        <SelectInput
                            source="person_type"
                            label="Тип аккаунта"
                            choices={[
                                { id: '1', name: 'Частное лицо' },
                                { id: '2', name: 'Агентство' },
                                { id: '3', name: 'Собственник' },
                            ]}
                            helperText={false}
                        />
                    </Box>
                </Box>
                <Box display="flex" flex="1" justifyContent="space-between" alignItems={isMobile ? "start" : "center"} mt={1} flexDirection={isMobile ? "column" : "row"}>
                    <Link to={`/realtyListings`}>
                        <Button variant="outlined" color="primary">
                            Объявления (обычный вид)
                        </Button>
                    </Link>
                    <Typography variant="body2" color='textSecondary' textAlign={isMobile ? "start" : "center"}  sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Парсер принимает запросы 1 раз в 6 секунд, Ваш поиск в порядке общей очереди.<br/> <strong>Пожалуйста, ищите то, что необходимо, и будьте терпеливы.</strong></Typography>
                    <Box mr={1} >
                        <Button variant="contained" color="primary" type="submit">
                            Показать объекты на карте
                        </Button>
                    </Box>
                </Box>
            </form>
        </FormProvider>
    );
};

// ListActions Component
const ListActions = ({ polygonCoords }) => (
    <TopToolbar sx={{ width: "100%" }}>
        <Card sx={{ width: "100%" }}>
            <CardContent sx={{ width: "100%" }}>
                <FilterForm polygonCoords={polygonCoords} />
            </CardContent>
        </Card>
    </TopToolbar>
);

const ListContent = ({ setPolygonCoords, polygonCoords }) => {
    const { data, isLoading, setFilters, isFetching } = useListContext(); // Use setFilters to trigger refetch
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [selectedRealty, setSelectedRealty] = React.useState(null);
    const [initialPolygonCoords, setInitialPolygonCoords] = React.useState(JSON.parse(`{"area[0][lat]":53.56719909306264,"area[0][lng]":36.01204354962194,"area[1][lat]":53.56719909306264,"area[1][lng]":39.25850351055944,"area[2][lat]":54.85499119693205,"area[2][lng]":39.25850351055944,"area[3][lat]":54.85499119693205,"area[3][lng]":36.01204354962194}`));
    const notify = useNotify();

    useEffect(() => {
        if((isFetching || isLoading) && polygonCoords.length > 0){
            notify('Загрузка объектов, пожалуйста подождите...', { type: 'info', undoable: false });
        }
    }, [isFetching]);

    // Handle Placemark click to show the realty object details on the same screen
    const handlePlacemarkClick = (record) => {
        setSelectedRealty(record);
    };

    // When the map bounds change, update the polygon coordinates and refetch data
    const handleBoundsChange = (event) => {
        const mapBounds = event.get('newBounds');
        const topLeft = mapBounds[0];
        const bottomRight = mapBounds[1];
        
        // Form the new polygon coordinates based on the map bounds
        const newPolygonCoords = [
            topLeft[0],
            topLeft[1],
            topLeft[0],
            bottomRight[1],
            bottomRight[0],
            bottomRight[1],
            bottomRight[0],
            topLeft[1]
        ];

        setPolygonCoords(newPolygonCoords);

        //console.log("AREA COORDS: " + JSON.stringify(newPolygonCoords));
    };

    return (
        <Box>
        <Box padding={2} display="flex" flex="1" flexWrap="wrap" >
            <Box display="flex" flex="1" flexDirection={isMobile ? "column" : "row"}>
            <YMaps>
                <Map
                    defaultState={{
                        center: [54.19383, 37.61587],
                        zoom: 9,
                    }}
                    width={isMobile ? "100%" : "50%"}
                    height={isMobile ? "300px" : "500px"}
                    onBoundsChange={handleBoundsChange} // Listen for bounds change
                >
                    {/* Render Polygon if polygonCoords are available */}
                    {polygonCoords.length > 0 && (
                        <Polygon
                            geometry={polygonCoords}
                            options={{
                                fillColor: '#00FF00',
                                strokeColor: '#0000FF',
                                opacity: 0.5,
                                strokeWidth: 2,
                            }}
                        />
                    )}

                    {/* Render Placemark for each data point */}
                    {data && data.map((record, index) => {
                        const lat = record.coords.lat;
                        const lng = record.coords.lng;

                        // Check for valid lat, lng
                        if (!lat || !lng) return null;

                        return (
                            <Placemark
                                key={index}
                                geometry={[lat, lng]}
                                options={{
                                    preset: 'islands#blueStretchyIcon',
                                }}
                                onClick={() => handlePlacemarkClick(record)} // Set the selected realty object on click
                            />
                        );
                    })}
                </Map>
            </YMaps>
            </Box>

            {selectedRealty && (
                <Box display="flex" flex="1" width="50%">
                    <Card>
                        <CardContent>
                            <Typography variant="h5">{selectedRealty.title}</Typography>

                            <Box mt={1} mb={1} display="flex">
                            <Link to={selectedRealty.url} target="_blank">
                                <Button variant="contained" color="primary" sx={{ marginRight: '1rem' }}>
                                    Открыть на {selectedRealty.source}
                                </Button>
                            </Link>
                            <Link to={`/realtyListings/${selectedRealty.id}/show`} target="_blank">
                                <Button variant="contained" color="primary">
                                    Открыть в CRM
                                </Button>
                            </Link>
                            </Box>

                            <Gallery images={selectedRealty.images} height="350" maxWidth="40rem" />

                            <Typography variant="body1" mt={1}>
                                <strong>Адрес:</strong> {selectedRealty.city}, {selectedRealty.address} {selectedRealty.metro}
                            </Typography>
                            <Typography variant="body1" mt={1}>
                                <strong>Цена:</strong> {selectedRealty.price} {selectedRealty.price_metric}
                            </Typography>
                            <Typography variant="body1" mt={1}>
                                <strong>Тип объявления:</strong> {selectedRealty.nedvigimost_type}
                            </Typography>
                            <Typography variant="body1" mt={1}>
                                <strong>Описание:</strong> {selectedRealty.description}
                            </Typography>

                            <Typography variant="body1" mt={1}>
                                <strong>Контактное лицо:</strong> {selectedRealty.person} {selectedRealty.contactname} {selectedRealty.person_type}
                            </Typography>

                            
                        </CardContent>
                    </Card>
                </Box>
            )}
        </Box>

        <Box padding={2}>
            <Grid container spacing={1}>
                {data && data.map((obj) => (
                    <Grid item xs={6} sm={6} md={2} key={obj.id}>
                        <Card sx={{ maxWidth: 250 }}>
                            <Gallery images={obj.images} height="140" />
                            <Link to={`/realtyListings/${obj.id}/show`} style={{ textDecoration: 'none' }} target="_blank" >
                                <CardContent sx={{ minHeight: 200 }}>
                                    <Typography
                                        gutterBottom
                                        variant="h6"
                                        component="div"
                                        sx={{
                                            display: '-webkit-box',
                                            WebkitBoxOrient: 'vertical',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            WebkitLineClamp: 2,
                                            height: '3rem'
                                        }}
                                    >
                                        {obj.title}
                                    </Typography>
                                    <Typography variant="body2"  color="textSecondary">
                                        {new Date(obj.time).toLocaleString('RU-ru')}
                                    </Typography>
                                    <Typography variant="body2" color="textPrimary">
                                        {obj.price} {obj.price_metric}, {obj.source}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        sx={{
                                            display: '-webkit-box',
                                            WebkitBoxOrient: 'vertical',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            WebkitLineClamp: 5,
                                            height: '6rem'
                                        }}
                                    >
                                        {obj.description}
                                    </Typography>
                                </CardContent>
                            </Link>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>

        </Box>
    );
};

// RealtyListingsMap Component
const RealtyListingsMap = () => {
    const [polygonCoords, setPolygonCoords] = useState([]);

    return (
        <RaList
            sx={{ marginTop: '1.5rem' }}
            resource="realtyListings"
            empty={false}
            pagination={false}
            actions={<ListActions polygonCoords={polygonCoords} />}
            queryOptions={{ refetchOnWindowFocus: false }}
            storeKey={false}
            filterDefaultValues={{ limit: 50 }}
        >
            <ListContent setPolygonCoords={setPolygonCoords}  polygonCoords={polygonCoords} />
        </RaList>
    );
};

export default RealtyListingsMap;
